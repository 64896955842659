export default {
  init() {
    $('.menuBtn').click(function() {
      event.preventDefault();
      $(this).toggleClass('act');

      if($(this).hasClass('act')) {
        $('.nav-primary').addClass('act');
        $('body').addClass('noscroll');
      }

      else {
        $('.nav-primary').removeClass('act');
        $('body').removeClass('noscroll');
      }
	});

    $('.audio-player').each(function() {
      var player = $(this);

      $('.play', player).click(function() {
        $(this).toggleClass('hide');
        $('.pause', player).toggleClass('hide');
      });

      $('.pause', player).click(function() {
        $(this).toggleClass('hide');
        $('.play', player).toggleClass('hide');
      });
    });
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
